<style type="text/css">
@import "~@/assets/css/common.css";
.overhide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fa-1x a {
  text-decoration: underline !important;
}
.f-column {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
td .btn {
  min-width: 85px;
  margin-right: 10px;
}
td .btn:last-child {
  margin-right: unset;
}
.el-popover {
  height: 200px;
}
</style>
<style scoped>
  .dialog-form .msgLabel{
    width: 100%;
  }
  .dialog-form .msgVal{
    line-height: 20px;
  }
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import $ from "jquery";
import applyProgress from "@/components/examInfo/applyProgress";

import { getStandardPhoto, getUserEdu } from "@/api/userInfo.js";
import { tipsLast, tipsRead } from "@/api/index.js";
import {
  getUserExamList,
  deleteApply,
  userSpecial,
} from "@/api/examination.js";
import { getWebNoticePage } from "@/api/notice/notice";
import { getUserInfo } from "@/libs/common";
/**
 * index
 */
export default {
  components: {
    Layout,
    PageHeader,
    applyProgress,
    // Chat,
    // Transaction
  },
  data() {
    return {
      title: "用户面板",
      items: [
        {
          text: "用户首页",
        },
        {
          text: "控制面板",
          active: true,
        },
      ],
      userInfo: {},
      userPhoto: {},
      pageData: { nowPage: 1, pageSize: 2, sfyd: false },
      testList: [],
      noticeList: [],
      xlwsd: 0,
      userImg: {},
      prompt: "",
      tipsInfo: null,
      tipShow: false,
      showDialog:false,
      shForm:{}
    };
  },
  methods: {
    closeModal() {
      this.tipShow = false;
    },
    tips() {
      tipsLast().then((res) => {
        if (res.status) {
          this.tipsInfo = res.data;
          this.tipShow = true;
          if (res.data && res.data.tid) {
            tipsRead(res.data.tid);
          }
        }
      });
    },
    getSpecial() {
      userSpecial().then((res) => {
        if (res.status) {
          let data = res.data;
          if (data && data.length) {
            this.testList.forEach((item) => {
              let filterData = data.filter((v) => {
                return v.ksbmbh == item.ksbmbh;
              })[0];
              if (filterData) {
                if (
                  filterData.yxsj &&
                  new Date().getTime() <
                    new Date(filterData.yxsj.replace(/-/g, "/")).getTime()
                ) {
                  if (filterData.sfxgbm) {
                    filterData.canEdit = true;
                  }
                  if (filterData.sfjf) {
                    filterData.canPay = true;
                  }
                }
                for (const key in filterData) {
                  if (!item[key]) {
                    item[key] = filterData[key];
                  }
                }
                this.$forceUpdate();
              }
            });
          }
        }
      });
    },
    getInfo() {
      getUserInfo().then((data) => {
        let arr = Object.keys(data);
        let num = 0;
        for (const key in data) {
          if (data[key]) {
            num += 1;
          }
        }
        data.zlwsd = Math.round((num / arr.length) * 100);
        this.userInfo = data;
        return;
      });
    },
    getUserPhoto() {
      getStandardPhoto().then((res) => {
        if (res.status) {
          this.userPhoto = res.data;
          // if (!this.userPhoto.bzz && !this.prompt) {
          //   this.prompt = "img";
          //   this.$message({
          //     showClose: true,
          //     duration: 0,
          //     message: "照片信息尚未完善，请前往完善",
          //     type: "warning",
          //   });
          // }
          let data = res.data;
          let list = ["bzz", "ldz", "hdz"];
          let imgList = [];
          list.forEach((item, index) => {
            imgList[index] = {};
            for (const key in data) {
              if (key.includes(item)) {
                let name = key.replace(item, "img");
                imgList[index][name] = data[key];
              }
            }
          });
          for (let index = 0; index < imgList.length; index++) {
            if (imgList[index].imgshzt == 1) {
              this.userImg = imgList[index];
              return;
            }
          }
          for (let index = 0; index < imgList.length; index++) {
            if (imgList[index].imgxthyzt == 1) {
              this.userImg = imgList[index];
              return;
            }
          }
          for (let index = 0; index < imgList.length; index++) {
            if (imgList[index].img) {
              this.userImg = imgList[index];
              return;
            }
          }
        }
      });
    },
    getEdu() {
      getUserEdu().then((res) => {
        if (res.status) {
          let list = res.data;
          if (list.length != 0) {
            this.xlwsd = 50;
            let filterData = list.filter((v) => {
              return (v.xlshzt ? v.xlshzt : v.xlxtrzzt) == 1;
            });
            if (filterData.length != 0) {
              this.xlwsd = 100;
            }
          } else {
            // if (!this.prompt) {
            //   this.prompt = "edu";
            //   this.$message({
            //     showClose: true,
            //     duration: 0,
            //     message: "学历信息尚未完善，请前往完善",
            //     type: "warning",
            //   });
            // }
          }

          this.eduList = res.data;
        }
      });
    },
    getUserExamList() {
      getUserExamList().then((res) => {
        if (res.status) {
          let newTime = new Date().getTime();
          let data = res.data.sort((x, y) => {
            return (
              new Date(y.createTime.replace(/-/g, "/")).getTime() -
              new Date(x.createTime.replace(/-/g, "/")).getTime()
            );
          });

          data.forEach((item) => {
            let examData = item.examRecordBean;
            if (examData) {
              if (!item.sfzstj) {
                //未正式提交
                item["canDelete"] = true;
              }
              if (examData.sfxyzgjgsh) {
                //需要审核
                if (
                  item.zgshzt != 9 &&
                  !(item.zgshzt == 1 && item.zpshzt == 1)
                ) {
                  //报名未锁定并且资格审核和照片审核有一个未通过
                  item["canDelete"] = true;
                }
              } else {
                //不需要审核
                if (examData.sfxyjf) {
                  if (item.jfzt != 1 && item.jfzt != 2) {
                    item["canDelete"] = true;
                  }
                }
              }
              if (
                examData.bmkssj &&
                examData.bmjssj &&
                new Date(examData.bmkssj.replace(/-/g, "/")).getTime() <
                  newTime &&
                new Date(examData.bmjssj.replace(/-/g, "/")).getTime() > newTime
              ) {
                item["canEdit"] = true;
              }
              if (
                examData.jfkssj &&
                examData.jfjssj &&
                new Date(examData.jfkssj.replace(/-/g, "/")).getTime() <
                  newTime &&
                new Date(examData.jfjssj.replace(/-/g, "/")).getTime() > newTime
              ) {
                item["canPay"] = true;
              }
              if (
                examData.zkzdykssj &&
                examData.zkzdyjssj &&
                new Date(examData.zkzdykssj.replace(/-/g, "/")).getTime() <
                  newTime &&
                new Date(examData.zkzdyjssj.replace(/-/g, "/")).getTime() >
                  newTime
              ) {
                item["canPrint"] = true;
              }
            }
          });
          this.testList = data;
          this.getSpecial();
          return;
        }
      });
    },
    // 获取数据列表
    getMessList() {
      getWebNoticePage(this.pageData).then((res) => {
        if (res.status) {
          this.noticeList = res.data;
        }
      });
    },

    repeal(index) {
      let _this = this;
      _this
        .$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let bmh = _this.testList[index].bmh;
          deleteApply(bmh).then((res) => {
            if (res.status) {
              _this.$message({
                message: res.message,
                type: "success",
              });
              _this.getUserExamList();
            }
          });
        })
        .catch();
    },
    pay(index) {
      let ksbmbh = this.testList[index].ksbmbh;
      let bmh = this.testList[index].bmh;
      this.$router.push({
        path: "/enrollForm",
        query: {
          ksbmbh: ksbmbh,
          bmh: bmh,
        },
      });
    },
    GoOn(index) {
      let ksbmbh = this.testList[index].ksbmbh;
      let bmh = this.testList[index].bmh;
      this.$router.push({
        path: "./enrollForm",
        query: {
          ksbmbh: ksbmbh,
          bmh: bmh,
        },
      });
    },
    GoPrint(index) {
      let ksbmbh = this.testList[index].ksbmbh;
      let bmh = this.testList[index].bmh;
      this.$router.push({
        path: "/ticketPrinting",
        query: {
          ksbmbh: ksbmbh,
          bmh: bmh,
        },
      });
    },
    goInfo(index) {
      let data = this.testList[index];
      this.$router.push({
        path: "./applyInfo",
        query: {
          ksbmbh: data.ksbmbh,
          bmh: data.bmh,
        },
      });
    },
    infoShow(index) {
      let routeUrl = "";
      let data = this.testList[index];
      if (data.examRecordBean.bmtzh) {
        routeUrl = this.$router.resolve({
          path: "/announcementDetails",
          query: {
            newsId: data.examRecordBean.bmtzh,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    dialogShow(e){
      this.shForm=e
      this.showDialog=true
    },
    init() {
      this.getInfo();
      this.getUserPhoto();
      this.getUserExamList();
      this.getMessList();
      this.getEdu();
      this.tips();
    },
  },
  created() {
    this.init();
  },
  mounted() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-8">
        <div class="card" style="min-height: 540px">
          <div class="card-body">
            <h4
              class="card-title pb-3 border-dash"
              style="justify-content: space-between"
            >
              报名记录
              <span
                ><a href="/record"
                  ><i class="iconfont icon-more head-more" id="more"></i
                ></a>
                <b-tooltip target="more" placement="bottom">更多</b-tooltip>
              </span>
            </h4>
            <div class="table-responsive border">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%">序号</th>
                    <th>考试名称</th>
                    <th style="min-width: 10%">报名状态</th>
                    <th style="min-width: 18%">主管部门电话</th>
                    <th style="width: 6%">详情</th>
                    <th style="min-width: 11%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in testList" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <a
                        href="javascript:void(0);"
                        @click="infoShow(index)"
                        class="blue-font"
                        >{{ item.ksmc }}
                      </a
                      >
                    </td>
                    <td>
                      <!-- <el-popover
                        placement="top"
                        title="报名进度"
                        width="1200"
                        trigger="hover"
                      >
                        <applyProgress :data="item"></applyProgress> -->

                      <div
                        class="text-danger"
                        slot="reference"
                        v-if="item.jfzt == 9"
                      >
                        已退费
                      </div>
                      <div
                        class="text-success"
                        slot="reference"
                        v-if="item.zgshzt == 1 && item.jfzt == 2"
                      >
                        报名成功（免缴费）
                      </div>
                      <div
                        class="text-success"
                        slot="reference"
                        v-if="item.zgshzt == 1 && item.jfzt == 1"
                      >
                        报名成功（已缴费）
                      </div>

                      <div
                          class="text-secondary"
                          slot="reference"
                          v-else-if="!(item.examRecordBean||{}).sfxyzgjgsh && item.zgshzt == 1 && item.sfzstj"
                      >
                        已提交，待缴费
                      </div>
                      <div
                        class="text-secondary"
                        slot="reference"
                        v-else-if="item.zgshzt == 1 && item.jfzt == 0"
                      >
                        已通过，待缴费
                      </div>
                      <div
                        class="text-secondary"
                        slot="reference"
                        v-if="
                          (item.zgshzt == 0 || item.zgshzt == null) &&
                          item.sfzstj
                        "
                      >
                        已提交，待审核
                      </div>
                      <div
                        class="text-danger"
                        slot="reference"
                        v-if="item.zgshzt == 2"
                        @click="dialogShow(item)"
                        style="text-decoration: underline;cursor: pointer"
                      >
                        审核未通过
                      </div>
                      <div
                        class="text-warning"
                        slot="reference"
                        v-if="item.zgshzt == 9"
                      >
                        已锁定
                      </div>
                      <div
                        class="text-secondary"
                        slot="reference"
                        v-if="
                          !item.sfzstj &&
                          (item.zgshzt == 0 || item.zgshzt == null)
                        "
                      >
                        未完成，请确认提交
                      </div>
                      <!-- </el-popover> -->
                    </td>
                    <td>{{ item.zgdwdh }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-info btn-sm"
                        @click="goInfo(index)"
                      >
                        详情
                      </button>
                    </td>
                    <td>
                      <div class="f-column">
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          v-if="item.zgshzt == 9"
                        >
                          禁止
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          v-if="item.canDelete && item.canEdit"
                          @click="repeal(index)"
                        >
                          删除
                        </button>
                        <button
                          type="button"
                          class="btn btn-info btn-sm"
                          v-if="item.jfzt == 1 && item.canPrint"
                          @click="GoPrint(index)"
                        >
                          打印准考证
                        </button>
                        <button
                          type="button"
                          class="btn btn-info btn-sm"
                          v-if="
                            (item.zgshzt == 0 || item.zgshzt == null) &&
                            item.canEdit &&
                            !item.sfzstj
                          "
                          @click="GoOn(index)"
                        >
                          确认提交
                        </button>
                        <button
                          type="button"
                          class="btn btn-info btn-sm"
                          v-if="
                            item.zgshzt == 1 &&
                            item.jfzt == 0 &&
                            item.canPay &&
                            item.examRecordBean.sfxyjf
                          "
                          @click="pay(index)"
                        >
                          缴费
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="testList.length == 0"
              class="d-flex justify-content-center"
            >
              暂无报名记录
            </div>
            <div>
              <router-link to="recordList">
                <img
                  src="@/assets/images/person/if-plus-circle.png"
                  class="mr-2"
                /><button type="button" class="btn btn-info h30">
                  添加新的报名
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <!-- <Chat /> -->
        <div class="card">
          <div class="card-body" style="min-height: 270px">
            <h4
              class="card-title pb-3 border-dash"
              style="justify-content: space-between"
            >
              个人信息
              <span
                ><a href="./personalData"
                  ><i class="iconfont icon-more head-more" id="more1"></i
                ></a>
                <b-tooltip target="more1" placement="bottom">更多</b-tooltip>
              </span>
            </h4>
            <div class="personinfo">
              <div class="d-flex">
                <div class="person-left">
                  <div class="imgcon">
                    <img
                      :src="
                        userImg.img
                          ? getFileUrl(userImg.img)
                          : require('@/assets/images/person/person-none.png')
                      "
                      alt=""
                    />
                  </div>
                  <a href="./personalImage">
                    <div
                      class="finish-check mt-2 flexList justify-content-center"
                      style="background-color: rgb(3 82 149); color: white"
                    >
                      <i
                        class="iconfont icon-plus-circle mr-2"
                        style="font-size: 18px; color: white"
                      ></i>
                      <span>修改照片信息</span>
                    </div>
                  </a>
                </div>
                <div class="person-right">
                  <div class="mb-2">
                    <i class="iconfont icon-ze-user-o mr-1"></i
                    ><span class="black-tip">用户姓名：</span>
                    <span>{{ userInfo.xm }}</span>
                  </div>
                  <div class="mb-2">
                    <i class="iconfont icon-idcard1e mr-1"></i
                    ><span class="black-tip">证件号码：</span>
                    <span>{{ userInfo.sfzjh }}</span>
                  </div>
                  <div class="mb-2">
                    <i class="iconfont icon-icons8-cell_phone mr-1"></i
                    ><span class="black-tip">移动电话：</span>
                    <span>{{ userInfo.yddh }}</span>
                  </div>
                  <div class="mb-2">
                    <div class="fa-1x">
                      基本资料完善度 <a href="./personalData">立即完善</a>
                    </div>
                    <div class="progress border-25 mt-1" style="height: 18px">
                      <div
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        aria-valuenow="25"
                        class="progress-bar bg-info border-25"
                        :style="{ width: userInfo.zlwsd + '%' }"
                      >
                        <font style="vertical-align: inherit">
                          <font style="vertical-align: inherit"
                            >{{ userInfo.zlwsd }}%</font
                          >
                        </font>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="fa-1x">
                      学历学位信息完善度
                      <a href="./personalEducation">立即完善</a>
                    </div>
                    <div
                      class="progress border-25 mt-1"
                      style="height: 18px; line-height: 18px"
                    >
                      <div
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        aria-valuenow="25"
                        class="progress-bar bg-info border-25"
                        :style="{ width: xlwsd + '%' }"
                      >
                        <font style="vertical-align: inherit">
                          <font style="vertical-align: inherit"
                            >{{ xlwsd }}%</font
                          >
                        </font>
                      </div>
                      {{ xlwsd == 0 ? "0%" : "" }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-danger">
                <div
                  v-if="
                    (userImg.imgshzt ? userImg.imgshzt : userImg.imgxthyzt) != 1
                  "
                >
                  {{
                    "系统提示：" +
                    (userImg.imgshsm || userImg.imgxthysm || "暂未上传证件照")
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" style="max-sheight: 230px">
          <div class="card-body">
            <h4
              class="card-title pb-3 border-dash"
              style="justify-content: space-between"
            >
              系统消息
              <span
                ><a href="/list"
                  ><i class="iconfont icon-more head-more" id="more1"></i
                ></a>
                <b-tooltip target="more1" placement="bottom">更多</b-tooltip>
              </span>
            </h4>
            <div v-if="!noticeList.length == 0">
              <div class="mb-2">
                <a
                  href="javascript:;"
                  @click="$yzNotice().show(n.noticeId)"
                  v-for="(n, v) in noticeList"
                  :key="v"
                >
                  <div class="d-flex border-dash p-2">
                    <img
                      src="@/assets/images/person/stLine-talk-l.png"
                      class="sys-img mr-2"
                      alt=""
                    />
                    <div>
                      <div class="line1 text-dark">{{ n.noticeTitle }}</div>
                      <div class="grey-time">{{ n.createTime }}</div>
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <a href="/list" class="blue-font"
                  ><div class="text-center font14">
                    <i class="arrow-down mr-2"></i>查看更多
                  </div></a
                >
              </div>
            </div>
            <div v-else class="flexList justify-content-center">
              暂无未读消息
            </div>
          </div>
        </div>
        <!-- <div class="card" style="height: 227px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              系统消息
              <span
                ><a href=""
                  ><i class="iconfont icon-more head-more" id="more2"></i
                ></a>
                <b-tooltip target="more2" placement="bottom">更多</b-tooltip>
              </span>
            </h4>
            <div class="mb-3">
              <a href="/details">
                <div class="d-flex border-dash p-2">
                  <img
                    src="@/assets/images/person/stLine-talk-l.png"
                    class="sys-img mr-2"
                    alt=""
                  />
                  <div>
                    <div class="text-dark line1">
                      报名 2021年四川省高级人民法院公开招聘聘用制书记员 已审核
                    </div>
                    <div class="grey-time">2021-12-06 17:57</div>
                  </div>
                </div>
              </a>
              <div class="d-flex border-dash p-2">
                <img
                  src="@/assets/images/person/iconPark-water-rate.png"
                  class="sys-img mr-2"
                  alt=""
                />
                <div>
                  <div class="text-dark line1">
                    报名 2021年四川省高级人民法院公开招聘聘用制书记员 已审核
                  </div>
                  <div class="grey-time">2021-12-06 17:57</div>
                </div>
              </div>
            </div>
            <div>
              <a href="/list" class="blue-font">
                <div class="text-center font14 h35">
                  <i class="arrow-down mr-2"></i>查看更多
                </div>
              </a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 导入学历弹窗 -->
    <b-modal
      v-if="tipsInfo"
      v-model="tipShow"
      id="importXL"
      centered
      :title="tipsInfo.bt"
      size="lg"
      title-class="font-18"
    >
      <div class="mb-2 tips-content" style="margin-top: -10px">
        <div v-html="tipsInfo.nr"></div>
      </div>
      <div class="border" slot="modal-footer">
        <button type="button" class="btn btn-primary" @click="closeModal">
          确定
        </button>
      </div>
    </b-modal>
<!-- 审核详情  -->
    <b-modal
            v-model="showDialog"
            centered
            title="审核详情"
            size="md"
            title-class="font-18"
    >
      <div class="dialog-form">
        <div class="d-flex msgContent">
          <div class="d-flex msgLabel ">
            <div class="msgName">报名状态</div>
            <div
                    class="msgVal  flexList"
            >
              <span v-if="shForm.jfzt == 9">已退费</span>
              <span v-if="shForm.zgshzt == 1 && shForm.jfzt == 2">报名成功（免缴费）</span>
              <span v-if="shForm.zgshzt == 1 &&  shForm.jfzt == 1">报名成功（已缴费）</span>
              <span v-if="shForm.zgshzt == 1 && shForm.jfzt == 0">已通过，待缴费</span>
              <span v-if="(shForm.zgshzt == 0 || shForm.zgshzt == null) &&shForm.sfzstj
                                    ">已提交，待审核</span>
              <span v-if="shForm.zgshzt == 2">审核未通过</span>
              <span v-if="shForm.zgshzt == 9">已锁定</span>

              <span v-if="!shForm.sfzstj &&(shForm.zgshzt == 0 || shForm.zgshzt == null)
                        ">未完成，请确认提交</span>


            </div>
          </div>

          <div class="d-flex msgLabel ">
            <div class="msgName">审核说明</div>
            <div
                    class="msgVal  flexList"

            >
              {{
              shForm.zgshsm

              }} <span class="text-danger">(修改报名信息或重新填报，须删除后重新提交)</span>
            </div>
          </div>
          <div class="d-flex msgLabel ">
            <div class="msgName">审核备注</div>
            <div class="msgVal  flexList ">
              {{shForm.zgshbz}}
            </div>
          </div>


        </div>

      </div>
      <div class="border" slot="modal-footer">

        <button type="button" class="btn btn-info btn-sm" @click="showDialog=false">
          确定
        </button>
      </div>
    </b-modal>
  </Layout>
</template>
